// -----------------------------------------------------------------------------
// This file contains styles that are specific to shopping and PayPal
// -----------------------------------------------------------------------------

.product__columns {
  flex-direction: column-reverse;
  @include grid-media ($small) {
    flex-direction: row;
  }
}
.product-description {
  @include grid-media ($small) {
    width: 400px;
  }
  padding: 25px;
  margin: 25px;
  background-color: lighten($action-color,60%);
}
.paypal-add-item {
  background-color: $action-color;
}
.paypal-add-item:hover {
  box-shadow: 0px 3px 5px #888888;
}

.paypal-add-item:active {
  box-shadow: 0 0 0 0;
}
.product-details {
  @include grid-media ($small) {
    /*display: flex;
    flex-direction: row;*/
    justify-content: center;
    align-items: center;
  }
}


.product {
  @include grid-media ($small) {
    /*display: flex;
    flex-direction: column;*/
    align-items: center;
    justify-content: center;
  }
  padding: 0.2em;
}

/* Snipcart styling */
.snipcart-checkout {

}

.snipcart-summary {
  //margin: 0 auto;
}

.buy-button {
  color: #fff;
  background-color: #008CBA;
  font-weight: 700;
  border-radius: 99px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  white-space: nowrap;
  transition: .2s transform ease-in-out,.2s box-shadow ease-in-out;
  box-shadow: 0 0 0 rgba(181,8,8,.2);
  display: block;
  margin: 1em auto;
  @include grid-media ($small) {
    margin: 2em 0;
  }
}

.buy-button:hover {
  //transform: translate(0px,-4px);
  box-shadow: 0 4px 6px rgba(181,8,8,.2)
}
.product__image {
  /*grid-area: image;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 18.025px 43.775px rgba(0,0,0,.25);*/
  //max-width: 400px;
}

.thumbnail {
  opacity: 0.5;
}
.thumbnail.active {
  opacity: 1;
}

// zoom image on hover
figure.zoom {
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}

figure.zoom img:hover {
  opacity: 0;
}

figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
  height: 100%;
}

figure.image {
  margin: 0 auto;
}