
.site--footer {
    padding: 1em;
    background-color: $white;
    text-align: center;
    a {
        //color: $link-color;
        &:active,
            &:focus,
            &:hover {
            //color: shade($link-color, 10%);
        }
        &:active,
            &:focus {
            outline: none;
        }
    }
}
.footer--wrapper {
    padding: 0 1em;
    margin-top: 1em;
}
///  Site Navigation (left)

/* not used in this site
.site--navigation {
    @include grid-column(12);
    @include grid-media ($small) {
        @include grid-column(6);
    }
    font-size: 0.9em;
    ul {
        @extend %default-ul !optional;
    }
}*/

///   Site Description/Signature (right)
.site--signature {
    margin-top: 1em;
    text-align: center;
    /*
    @include grid-column(12);
    @include grid-media ($small) {
        @include grid-column(6);
        margin-right: 0;
        margin-top: 0;
    }
    */
    font-size: 0.9em;
    a {
        text-decoration: underline;
    }
}

.social-media-list {
    padding: 1em 0;
    a {
        text-decoration: none;
    }
}

///   Contact Info (left)

.site--contact {
    margin-top: 1em;
    /*
    @include grid-column(12);
    @include grid-media ($small) {
        @include grid-column(6);
        margin-top: 0;
        margin-right: 0;
    }
    */

    font-size: 0.9em;
    i {
        width: 1.25em;
        text-align: center;
    }
    ul {
        @extend %default-ul !optional;
    }
}
.site--copyright {
    margin-top: 1em;
    text-align: center;
}

/// And now, with the grid
@supports (display: grid) {

    .site--footer {
        // positioning of main body grid - push down to bottom of page
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .footer--wrapper {
        display: grid;  // this is the container
        grid-gap: 10px;
        justify-items: center;
        // how many columns in this grid?
        // NONE!
        //grid-template-columns: repeat(3, 33% [col-start]);
    }
}
