
.ft-image {
    margin: 0 auto;
    @include grid-media ($small) { // 488px

    }
    @include grid-media ($large) { // 960px
        max-width: 500px;
        max-height: 500px;
    }
}

/// 100% width Gallery blocks
.gallery--cover {
    margin: 0 auto 2em auto;
    max-width: 1200px;
    a {
        color: $action-color;
        text-decoration: none;
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            color: darken($highlight-color,10%);
            .gallery {
                background: rgba(255,255,255,1);
            }
        }
    }
}
.gallery--feature {
  // featured image on gallery page
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1em;
}
.gallery--link {
    text-decoration: none;
}
.gallery--list {

    /// make it a flexbox so items can be aligned vertically
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    align-content: center;

    // now grid it
    @supports (display: grid) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }


    a {
        img {
            //opacity: 1;
            transition: all 0.2s ease-in-out;
            &:hover,
            &:active,
            &:focus {
                opacity: 0.8;
                transition: all 0.2s ease-in-out
            }
        }
        h3 {
            color: $base-font-color;
        }

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            color: darken($action-color, 20%);
            h3 {
                //color: $camouflage;
            }
        }
    }
}
.gallery--front {
    padding: 0 1em 2em;
    background: rgba(255, 255, 255, 0.9);
}

.gallery--header {
    padding: 1em 0;
}
.gallery--box,
.gallery--box-large {
    @include grid-media ($small) {
        flex: 0 1 auto;
    }
    margin: 1em;
    text-align: center;
}

.gallery--caption {
    font-style: normal;
}

/// Remove widths for grids
@supports (display: grid) {

  .gallery--box-50,
  .gallery--box,
  .gallery--box-large,
  .gallery--box-home {
    width: auto;
  }
}