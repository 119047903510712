
dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

ul {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 15px;
  margin-bottom: 15px;
}
ol {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 15px;
  margin-bottom: 15px;
}
ul.default-ul,
ol.default-ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list--horizontal {
  list-style-type: none;
  margin: 0;
  //display: inline;

  li {
    display: inline;
    padding-right: 1em;
  }
}
.list--bulleted {
  list-style-type: none;
  list-style-position: inside;
  margin-left: 15px;
  margin-bottom: 10px;

  li::before {
    content: "•";
    color: $action-color;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

