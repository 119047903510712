
.header--wrapper {
    /* flex horizontal centering */
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex-flow: row wrap;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        //color: shade($link-color, 10%);
    }
    &:active,
    &:focus {
        outline: none;
    }
}
.header--logo {

}

.header--title {
    align-self: center; // flex centered

    font-family: $logo-font-family;
    color: $dark-gray;
    //text-transform: uppercase;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 0;

    font-size: modular-scale(3);

    @include grid-media ($small) {
        font-size: modular-scale(5);
    }
}

/// And now, with the grid
/* https://gridbyexample.com/ */
@supports (display: grid) {
    .header--wrapper {
        display: grid;
        grid-gap: 10px;
        justify-items: center;
    }
    .header--logo {
        justify-self: center;
        width: auto;
        max-width: 150px;
    }
}

.subtitle {
    color: lighten($base-font-color,40%);
    font-family: $subheading-font-family;
    font-weight: 400;
    font-size: modular-scale(3);
    margin-bottom: 0.5em;
}

/**
 * Snipcart
 */
.snipcart-checkout {
    text-align: center;
}
.snipcart-summary {
    margin: 0 auto;
}