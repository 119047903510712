
// 1. Configuration and helpers
// Bourbon and Neat and Bitters /base are already imported in /css/main.scss
// TO DO - remove Bitters  in /base folder

// 2. Eye Gallery theme and client custom file
@import  'theme/eyegallery';
//@import  'theme/vanilla';

// 3. Vendors
@import 'vendor/flexslider';
@import 'vendor-extensions/flexslider';
@import 'vendor-extensions/lightbox';
@import 'vendor-extensions/mailchimp';

// 4. Layout
@import	'layout/header';
@import 'layout/footer';
@import 'layout/navigation';
@import 'layout/gallery';
@import 'layout/forms';

// 5. Components
@import	'better-bourbon-buttons/bbb';
@import 'components/page';
@import 'components/helpers';
@import 'components/post';
@import 'components/carousel';
@import 'components/cloudcannon';
@import 'components/cover';
@import 'components/product';
@import 'components/svg';

// 6. Page-specific
@import	'pages/home';


// 7. Shame - nowhere found for this yet
@import 'shame';

div.highlight {
    margin-bottom: 1rem;
}

@include desktop {
    .navbar-item {
        &.is-hoverable:hover {
            .navbar-dropdown {
                display: block;
            }
        }
    }
}
 
  
.contents {
    box-shadow: $box-shadow;
    padding: 1.5rem;
    margin-bottom: 3rem;
}

.hero-darken {
    background-color: rgba($hero-darken, 0.5);
}
.hero.is-medium .hero-body {
    padding-top: 5rem;
    padding-bottom: 2rem;
}
.content {
    figure {
        margin: 0;
    }
}

.callout {
    &-icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 0.75rem;
    }
}

// Alpine.js hide on initial load
[x-cloak] { display: none !important; }

// Cloudcannon editor
.editor-content {
    display: none;
}

.cms-editor-active .editor-content {
    display: block;
}