// SVG icons
.icon {
    fill: lighten($base-font-color,40%);
    display: inline-block;
    width: 30px;
    height: 30px;
}
a:hover .icon,
a:active .icon { /// hover colour
    fill: $action-hover-color;
}
.icon--readmore {
    fill: $white;
    margin-bottom: -5px;
    width: 20px;
    height: 20px;
}
.icon--post {
    fill: $navigation-color;
}
.icon--inactive {
    fill: $medium-gray;
}
.icon--logo {
    fill: $highlight-color;
    width: 20px;
    height: 20px;
}
.icon-facebook_sign { fill: $facebook; }
.icon-instagram { fill: $instagram; }
.icon-twitter_sign { fill: $twitter; }
.icon-youtube { fill: $youtube; }
.icon-soundcloud { fill: $soundcloud; }
.icon-vimeo_square { fill: $vimeo; }
.icon-pinterest { fill: $pinterest; }

/// vertical align SVG icons in the centre
a svg {
    display: inline-block;
    vertical-align: middle;
}
.icon--light {
    fill: $white;
}
a:hover .icon--light { /// hover colour
    fill: shade($white, 20%);
}