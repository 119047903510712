html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}
h1 {
  font-size: modular-scale(2);
  @include grid-media ($small) {
    font-size: modular-scale(4);
  }
}
h2 {
  font-size: modular-scale(2);
  @include grid-media ($small) {
    font-size: modular-scale(3);
  }
}
h3 {
  font-size: modular-scale(2);
}
h4 {
  font-size: modular-scale(1);
}
h5 {
  font-size: modular-scale(-1);
}
h6 {
  font-size: modular-scale(-2);
}
.hdr--small {
  font-size: modular-scale(0);
}


p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  transition: color $base-duration $base-timing;

  &:hover {
    color: shade($action-color, 25%);
  }

  &:focus {
    //outline: $focus-outline;
    //outline-offset: $focus-outline-offset;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.textright {
    text-align: right;
}
blockquote {
  padding: 1em 0;
  border-bottom: 1px solid $action-color;
  font-style: italic;
}

strong {
  color: $black;
  font-weight: 700;
}